/* Global styles */
.asq-chat-app-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.asq-chat-content,
.asq-chat-content-messages {
  flex: 1;
  overflow-y: auto;
}

.asq-chat-content-messages {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word; /* Legacy property for older browsers */
  word-break: break-word;
}

/* Message styling */
.asq-chat-content-ai-message,
.asq-chat-content-user-message,
.asq-chat-alert-message {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 85%;
  text-align: left;
  white-space: pre-wrap;
}

.asq-chat-content-ai-message {
  align-self: flex-start;
}

.asq-chat-content-user-message {
  align-self: flex-end;
}

.asq-chat-alert-message {
  align-self: flex-start;
}

/* Suggestions and interactions */
.asq-chat-content-suggestions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  padding: 0 10px;
}

.ask-chat-content-suggest-btn {
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px 10px;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

/* ChatInput component */
.asq-chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 2;
  width: -webkit-fill-available;
}

.asq-chat-input input {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: medium;
}

.asq-chat-input button {
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.asq-chat-input button:disabled {
  cursor: not-allowed;
}

/* ChatStatus messages */
.asq-chat-status-container {
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
}

.asq-chat-status-message {
  width: 90%;
  font-size: 16px;
  padding-bottom: 20px;
}

/* AskingQuestion component */
.asq-chat-asking-question {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 60px;
  height: 30px;
  border-radius: 5px;
}

.asq-chat-asking-question-dots-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}

.asq-chat-asking-question-dot-0 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  animation: asq-chat-asking-question-animation 1s infinite;
}

.asq-chat-asking-question-dot-1 {
  animation-delay: 0.2s;
  margin-left: 5px;
}

.asq-chat-asking-question-dot-2 {
  animation-delay: 0.4s;
}

@keyframes asq-chat-asking-question-animation {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

/* ChatHeader component */
.asq-chat-header {
  position: sticky;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.asq-chat-header-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.asq-chat-header-circle {
  width: 12px;
  height: 12px;
  margin-right: 7px;
  border-radius: 50%;
}

.asq-chat-header-buttons {
  display: flex;
}

.ask-chat-header-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  font-size: 1rem;
}

.ask-chat-header-button:hover {
  transform: scale(1.1);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.ask-chat-header-button:hover .anticon {
  font-size: 1.2rem;
}

/* Application Errors */
.asq-app-error-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 90%;
  max-width: 600px;
}

.asq-app-error-title {
  font-size: 24px;
  font-weight: bold;
}

.asq-app-error-message {
  font-size: 16px;
}

/* FeedbackForm component */
.asq-chat-feeback-container {
  max-width: 380px;
  height: auto;
  overflow: hidden;
  right: 30px;
  bottom: 30px;
}

.asq-chat-feedback-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  padding: 20px;
}

.asq-chat-feedback-thanks-message {
  margin-top: 20px;
  font-size: 20px;
}

.asq-chat-feedback-star-rating {
  margin-top: 15px;
  font-size: 36px;
}

.asq-chat-login-form {
  padding: 20px;
  margin: 0 auto;
  max-width: 380px;
  height: auto;
}

.asq-chat-login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.asq-chat-login-form .wide-button {
  width: 100%;
}
/* Responsive adjustments */
.hide-on-mobile {
  display: none;
}

@media screen and (min-width: 400px) {
  .hide-on-mobile {
    display: inline-flex;
  }
}
